var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.$t('pricing'),"label-for":"pricingAmount","description":"A játék ára. Ingyenes játék esetén írj 0-t az Ár mezőbe. Ha nem Ft, € vagy $-ban van megadva, számold át ezek valamelyikére."}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-select',{attrs:{"id":"pricingRecurrency","value":_vm.value ? _vm.value.recurrency : null,"required":_vm.required,"options":[
          { text: _vm.$t('once'), value: 'once' },
          { text: _vm.$t('monthly'), value: 'monthly' },
          { text: _vm.$t('yearly'), value: 'yearly' }
        ]},on:{"change":_vm.changeRecurrency}})],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"pricingAmount","type":"number","required":_vm.required,"value":_vm.value ? _vm.value.amount : null,"placeholder":"Ár","min":"0"},on:{"input":_vm.changeAmount}}),_c('b-select',{attrs:{"id":"pricingCurrency","required":_vm.required,"value":_vm.value ? _vm.value.currency : null,"options":[
            { text: _vm.$t('HUF'), value: 'HUF' },
            { text: _vm.$t('EUR'), value: 'EUR' },
            { text: _vm.$t('USD'), value: 'USD' }
          ]},on:{"change":_vm.changeCurrency}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"4"}},[_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" / ")]),_c('b-select',{attrs:{"id":"pricingPayer","required":_vm.required,"value":_vm.value ? _vm.value.payer : null,"options":[
            { text: _vm.$t('payer-one'), value: 'one' },
            { text: _vm.$t('payer-all'), value: 'all' }
          ]},on:{"change":_vm.changePayer}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }