<template>
  <b-form-group
    :label="$t('pricing')"
    label-for="pricingAmount"
    description="A játék ára. Ingyenes játék esetén írj 0-t az Ár mezőbe. Ha nem Ft, € vagy $-ban van megadva, számold át ezek valamelyikére."
  >
    <b-row>
      <b-col cols="12" md="4" class="mb-2">
        <b-select
          id="pricingRecurrency"
          :value="value ? value.recurrency : null"
          :required="required"
          :options="[
            { text: $t('once'), value: 'once' },
            { text: $t('monthly'), value: 'monthly' },
            { text: $t('yearly'), value: 'yearly' }
          ]"
          @change="changeRecurrency"
        />
      </b-col>
      <b-col cols="12" md="4" class="mb-2">
        <b-input-group>
          <b-form-input
            id="pricingAmount"
            type="number"
            :required="required"
            :value="value ? value.amount : null"
            @input="changeAmount"
            placeholder="Ár"
            min="0"
          ></b-form-input>
          <b-select
            id="pricingCurrency"
            :required="required"
            :value="value ? value.currency : null"
            :options="[
              { text: $t('HUF'), value: 'HUF' },
              { text: $t('EUR'), value: 'EUR' },
              { text: $t('USD'), value: 'USD' }
            ]"
            @change="changeCurrency"
          />
        </b-input-group>
      </b-col>
      <b-col cols="12" md="4" class="mb-2">
        <b-input-group>
          <b-input-group-prepend is-text>
            /
          </b-input-group-prepend>
          <b-select
            id="pricingPayer"
            :required="required"
            :value="value ? value.payer : null"
            :options="[
              { text: $t('payer-one'), value: 'one' },
              { text: $t('payer-all'), value: 'all' }
            ]"
            @change="changePayer"
          />
        </b-input-group>
      </b-col>
    </b-row>
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  data: () => ({ recurrency: null, amount: null, currency: null, payer: null }),
  mounted() {
    this.recurrency = this.value ? this.value.recurrency : null;
    this.amount = this.value ? this.value.amount : null;
    this.currency = this.value ? this.value.currency : null;
    this.payer = this.value ? this.value.payer : null;
  },
  computed: {
    required() {
      if (this.amount === 0) return false;
      return !!Object.values(this.value || {}).filter((val) => !!val).length;
    }
  },
  methods: {
    change() {
      const obj = {};
      if (this.amount === 0) {
        this.$emit("input", { amount: 0 });
        return;
      }
      if (!this.amount) {
        this.$emit("input", undefined);
        return;
      }

      if (this.recurrency) obj.recurrency = this.recurrency;
      if (this.amount) obj.amount = this.amount;
      if (this.currency) obj.currency = this.currency;
      if (this.payer) obj.payer = this.payer;
      this.$emit("input", Object.keys(obj).length ? obj : undefined);
    },
    changeRecurrency(v) {
      this.recurrency = v;
      this.change();
    },
    changeAmount(v) {
      this.amount = parseInt(v);
      this.change();
    },
    changeCurrency(v) {
      this.currency = v;
      this.change();
    },
    changePayer(v) {
      this.payer = v;
      this.change();
    }
  }
};
</script>
