<template>
  <b-form-group
    :label="$t('playerCount')"
    label-for="playerCountFrom"
    description="Ahányan egyszerre tudnak együtt játszani"
  >
    <b-input-group>
      <b-form-input
        id="playerCountFrom"
        type="number"
        :value="value ? value.from : null"
        @input="changeFrom"
        placeholder="Min"
        min="0"
      ></b-form-input>
      <b-input-group-addon is-text>
        -
      </b-input-group-addon>
      <b-form-input
        id="playerCountTo"
        type="number"
        :value="value ? value.to : null"
        @input="changeTo"
        placeholder="Max"
        min="0"
      ></b-form-input>
    </b-input-group>
  </b-form-group>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  },
  data: () => ({ from: null, to: null }),
  mounted() {
    this.from = this.value ? this.value.from : null;
    this.to = this.value ? this.value.to : null;
  },
  methods: {
    change() {
      const obj = {};
      if (this.from) obj.from = this.from;
      if (this.to) obj.to = this.to;
      this.$emit("input", Object.keys(obj).length ? obj : undefined);
    },
    changeFrom(v) {
      this.from = parseInt(v);
      this.change();
    },
    changeTo(v) {
      this.to = parseInt(v);
      this.change();
    }
  }
};
</script>
